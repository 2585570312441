<template>
	<div class="housing-card">
		<or-image
			:src="content.image"
			width="380"
			height="310"
			:alt="'Example housing'"
		/>

		<div class="housing-card__content">
			<h3 class="housing-card__title">{{ content.title }}</h3>
			<p class="housing-card__offers">
				<span class="housing-card__offers--count">
					{{ content.announcement_count }}
				</span>
				{{ $t('general.offers') }}
			</p>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		content: {
			type: Object,
			default: () => ({})
		}
	}
}
</script>

<style lang="scss">
.housing-card {
	cursor: pointer;
	width: 380px;
	height: 100%;
	img {
		border-radius: 5px;
		object-fit: cover;
	}
	.skeleton-basic {
		height: 310px;
	}
	&__content {
		margin-top: 15px;
		text-align: left;
	}

	&__title {
		margin: 0;
		font-weight: 600;
		font-size: 14px;
		line-height: 19px;
	}

	&__offers {
		margin: 0;
		margin-top: 5px;
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
		color: #929292;

		&--count {
			font-weight: 700;
			color: #c99d56;
		}
	}
}
</style>
